import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c9e34242 = () => interopDefault(import('../pages/activiteiten-trainingen/index.vue' /* webpackChunkName: "pages/activiteiten-trainingen/index" */))
const _ab373db2 = () => interopDefault(import('../pages/clienttevredenheidsonderzoek.vue' /* webpackChunkName: "pages/clienttevredenheidsonderzoek" */))
const _0ecdd60a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ff4aab1e = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _234a6fa6 = () => interopDefault(import('../pages/inschrijvingen/index.vue' /* webpackChunkName: "pages/inschrijvingen/index" */))
const _023e6c31 = () => interopDefault(import('../pages/lid-worden/index.vue' /* webpackChunkName: "pages/lid-worden/index" */))
const _006b859f = () => interopDefault(import('../pages/mentoring/index.vue' /* webpackChunkName: "pages/mentoring/index" */))
const _35517462 = () => interopDefault(import('../pages/mijn-profiel/index.vue' /* webpackChunkName: "pages/mijn-profiel/index" */))
const _bde97c12 = () => interopDefault(import('../pages/opleiding-training-en-boekrecensies/index.vue' /* webpackChunkName: "pages/opleiding-training-en-boekrecensies/index" */))
const _0cf9a6f0 = () => interopDefault(import('../pages/over-nvpa/index.vue' /* webpackChunkName: "pages/over-nvpa/index" */))
const _bf7fbf0c = () => interopDefault(import('../pages/studenten-lidmaatschap/index.vue' /* webpackChunkName: "pages/studenten-lidmaatschap/index" */))
const _3de29adb = () => interopDefault(import('../pages/van-en-voor-leden/index.vue' /* webpackChunkName: "pages/van-en-voor-leden/index" */))
const _f1a5bb0e = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _267b7689 = () => interopDefault(import('../pages/zorgverzekering.vue' /* webpackChunkName: "pages/zorgverzekering" */))
const _63464692 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _77d152af = () => interopDefault(import('../pages/mijn-profiel/activiteiten.vue' /* webpackChunkName: "pages/mijn-profiel/activiteiten" */))
const _5cdcc924 = () => interopDefault(import('../pages/mijn-profiel/dashboard.vue' /* webpackChunkName: "pages/mijn-profiel/dashboard" */))
const _6f8a86b8 = () => interopDefault(import('../pages/mijn-profiel/documenten.vue' /* webpackChunkName: "pages/mijn-profiel/documenten" */))
const _93cdc5be = () => interopDefault(import('../pages/mijn-profiel/mijn-documenten.vue' /* webpackChunkName: "pages/mijn-profiel/mijn-documenten" */))
const _1c0e9bdf = () => interopDefault(import('../pages/mijn-profiel/promotiemateriaal.vue' /* webpackChunkName: "pages/mijn-profiel/promotiemateriaal" */))
const _ef08b8a0 = () => interopDefault(import('../pages/mijn-profiel/scholing/index.vue' /* webpackChunkName: "pages/mijn-profiel/scholing/index" */))
const _386fa25c = () => interopDefault(import('../pages/mijn-profiel/vakgroep/index.vue' /* webpackChunkName: "pages/mijn-profiel/vakgroep/index" */))
const _2c730014 = () => interopDefault(import('../pages/opleiding-training-en-boekrecensies/nieuw/index.vue' /* webpackChunkName: "pages/opleiding-training-en-boekrecensies/nieuw/index" */))
const _59fbfadc = () => interopDefault(import('../pages/over-nvpa/nieuws/index.vue' /* webpackChunkName: "pages/over-nvpa/nieuws/index" */))
const _1b91dee9 = () => interopDefault(import('../pages/studenten-lidmaatschap/student-inschrijving/index.vue' /* webpackChunkName: "pages/studenten-lidmaatschap/student-inschrijving/index" */))
const _4e9baffc = () => interopDefault(import('../pages/therapeut/vinden.vue' /* webpackChunkName: "pages/therapeut/vinden" */))
const _6515c410 = () => interopDefault(import('../pages/van-en-voor-leden/nieuw/index.vue' /* webpackChunkName: "pages/van-en-voor-leden/nieuw/index" */))
const _3f54a440 = () => interopDefault(import('../pages/auth/password/reset/index.vue' /* webpackChunkName: "pages/auth/password/reset/index" */))
const _54fbbcfc = () => interopDefault(import('../pages/mijn-profiel/scholing/uploaden.vue' /* webpackChunkName: "pages/mijn-profiel/scholing/uploaden" */))
const _31077b7c = () => interopDefault(import('../pages/auth/password/reset/_token.vue' /* webpackChunkName: "pages/auth/password/reset/_token" */))
const _6cd5568e = () => interopDefault(import('../pages/auth/invite/_token.vue' /* webpackChunkName: "pages/auth/invite/_token" */))
const _83970442 = () => interopDefault(import('../pages/lid-worden/ideal/_id.vue' /* webpackChunkName: "pages/lid-worden/ideal/_id" */))
const _4c7b669b = () => interopDefault(import('../pages/mijn-profiel/scholing/_id/index.vue' /* webpackChunkName: "pages/mijn-profiel/scholing/_id/index" */))
const _3bdce4ec = () => interopDefault(import('../pages/mijn-profiel/vakgroep/_slug.vue' /* webpackChunkName: "pages/mijn-profiel/vakgroep/_slug" */))
const _58455994 = () => interopDefault(import('../pages/over-nvpa/nieuws/_slug.vue' /* webpackChunkName: "pages/over-nvpa/nieuws/_slug" */))
const _3d6f76da = () => interopDefault(import('../pages/activiteiten-trainingen/_slug/index.vue' /* webpackChunkName: "pages/activiteiten-trainingen/_slug/index" */))
const _a5ebf6d0 = () => interopDefault(import('../pages/inschrijvingen/_id/index.vue' /* webpackChunkName: "pages/inschrijvingen/_id/index" */))
const _1ae8b01c = () => interopDefault(import('../pages/opleiding-training-en-boekrecensies/_slug/index.vue' /* webpackChunkName: "pages/opleiding-training-en-boekrecensies/_slug/index" */))
const _9eb06c6c = () => interopDefault(import('../pages/therapeut/_slug.vue' /* webpackChunkName: "pages/therapeut/_slug" */))
const _d8e47454 = () => interopDefault(import('../pages/van-en-voor-leden/_slug/index.vue' /* webpackChunkName: "pages/van-en-voor-leden/_slug/index" */))
const _5532dd46 = () => interopDefault(import('../pages/opleiding-training-en-boekrecensies/_slug/bewerken/index.vue' /* webpackChunkName: "pages/opleiding-training-en-boekrecensies/_slug/bewerken/index" */))
const _ccabc03c = () => interopDefault(import('../pages/van-en-voor-leden/_slug/bewerken/index.vue' /* webpackChunkName: "pages/van-en-voor-leden/_slug/bewerken/index" */))
const _f487b526 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0fe867fa = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activiteiten-trainingen",
    component: _c9e34242,
    pathToRegexpOptions: {"strict":true},
    name: "activiteiten-trainingen"
  }, {
    path: "/clienttevredenheidsonderzoek",
    component: _ab373db2,
    pathToRegexpOptions: {"strict":true},
    name: "clienttevredenheidsonderzoek"
  }, {
    path: "/contact",
    component: _0ecdd60a,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/faq",
    component: _ff4aab1e,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/inschrijvingen",
    component: _234a6fa6,
    pathToRegexpOptions: {"strict":true},
    name: "inschrijvingen"
  }, {
    path: "/lid-worden",
    component: _023e6c31,
    pathToRegexpOptions: {"strict":true},
    name: "lid-worden"
  }, {
    path: "/mentoring",
    component: _006b859f,
    pathToRegexpOptions: {"strict":true},
    name: "mentoring"
  }, {
    path: "/mijn-profiel",
    component: _35517462,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel"
  }, {
    path: "/opleiding-training-en-boekrecensies",
    component: _bde97c12,
    pathToRegexpOptions: {"strict":true},
    name: "opleiding-training-en-boekrecensies"
  }, {
    path: "/over-nvpa",
    component: _0cf9a6f0,
    pathToRegexpOptions: {"strict":true},
    name: "over-nvpa"
  }, {
    path: "/studenten-lidmaatschap",
    component: _bf7fbf0c,
    pathToRegexpOptions: {"strict":true},
    name: "studenten-lidmaatschap"
  }, {
    path: "/van-en-voor-leden",
    component: _3de29adb,
    pathToRegexpOptions: {"strict":true},
    name: "van-en-voor-leden"
  }, {
    path: "/zoeken",
    component: _f1a5bb0e,
    pathToRegexpOptions: {"strict":true},
    name: "zoeken"
  }, {
    path: "/zorgverzekering",
    component: _267b7689,
    pathToRegexpOptions: {"strict":true},
    name: "zorgverzekering"
  }, {
    path: "/auth/login",
    component: _63464692,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login"
  }, {
    path: "/mijn-profiel/activiteiten",
    component: _77d152af,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-activiteiten"
  }, {
    path: "/mijn-profiel/dashboard",
    component: _5cdcc924,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-dashboard"
  }, {
    path: "/mijn-profiel/documenten",
    component: _6f8a86b8,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-documenten"
  }, {
    path: "/mijn-profiel/mijn-documenten",
    component: _93cdc5be,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-mijn-documenten"
  }, {
    path: "/mijn-profiel/promotiemateriaal",
    component: _1c0e9bdf,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-promotiemateriaal"
  }, {
    path: "/mijn-profiel/scholing",
    component: _ef08b8a0,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-scholing"
  }, {
    path: "/mijn-profiel/vakgroep",
    component: _386fa25c,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-vakgroep"
  }, {
    path: "/opleiding-training-en-boekrecensies/nieuw",
    component: _2c730014,
    pathToRegexpOptions: {"strict":true},
    name: "opleiding-training-en-boekrecensies-nieuw"
  }, {
    path: "/over-nvpa/nieuws",
    component: _59fbfadc,
    pathToRegexpOptions: {"strict":true},
    name: "over-nvpa-nieuws"
  }, {
    path: "/studenten-lidmaatschap/student-inschrijving",
    component: _1b91dee9,
    pathToRegexpOptions: {"strict":true},
    name: "studenten-lidmaatschap-student-inschrijving"
  }, {
    path: "/therapeut/vinden",
    component: _4e9baffc,
    pathToRegexpOptions: {"strict":true},
    name: "therapeut-vinden"
  }, {
    path: "/van-en-voor-leden/nieuw",
    component: _6515c410,
    pathToRegexpOptions: {"strict":true},
    name: "van-en-voor-leden-nieuw"
  }, {
    path: "/auth/password/reset",
    component: _3f54a440,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset"
  }, {
    path: "/mijn-profiel/scholing/uploaden",
    component: _54fbbcfc,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-scholing-uploaden"
  }, {
    path: "/auth/password/reset/:token",
    component: _31077b7c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset-token"
  }, {
    path: "/auth/invite/:token?",
    component: _6cd5568e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-invite-token"
  }, {
    path: "/lid-worden/ideal/:id",
    component: _83970442,
    pathToRegexpOptions: {"strict":true},
    name: "lid-worden-ideal-id"
  }, {
    path: "/mijn-profiel/scholing/:id",
    component: _4c7b669b,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-scholing-id"
  }, {
    path: "/mijn-profiel/vakgroep/:slug",
    component: _3bdce4ec,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-profiel-vakgroep-slug"
  }, {
    path: "/over-nvpa/nieuws/:slug",
    component: _58455994,
    pathToRegexpOptions: {"strict":true},
    name: "over-nvpa-nieuws-slug"
  }, {
    path: "/activiteiten-trainingen/:slug",
    component: _3d6f76da,
    pathToRegexpOptions: {"strict":true},
    name: "activiteiten-trainingen-slug"
  }, {
    path: "/inschrijvingen/:id",
    component: _a5ebf6d0,
    pathToRegexpOptions: {"strict":true},
    name: "inschrijvingen-id"
  }, {
    path: "/opleiding-training-en-boekrecensies/:slug",
    component: _1ae8b01c,
    pathToRegexpOptions: {"strict":true},
    name: "opleiding-training-en-boekrecensies-slug"
  }, {
    path: "/therapeut/:slug?",
    component: _9eb06c6c,
    pathToRegexpOptions: {"strict":true},
    name: "therapeut-slug"
  }, {
    path: "/van-en-voor-leden/:slug",
    component: _d8e47454,
    pathToRegexpOptions: {"strict":true},
    name: "van-en-voor-leden-slug"
  }, {
    path: "/opleiding-training-en-boekrecensies/:slug?/bewerken",
    component: _5532dd46,
    pathToRegexpOptions: {"strict":true},
    name: "opleiding-training-en-boekrecensies-slug-bewerken"
  }, {
    path: "/van-en-voor-leden/:slug?/bewerken",
    component: _ccabc03c,
    pathToRegexpOptions: {"strict":true},
    name: "van-en-voor-leden-slug-bewerken"
  }, {
    path: "/",
    component: _f487b526,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    component: _0fe867fa,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
